<template>
  <div id="app">
    <!-- <nav>
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/about">About</router-link>
        </li>
      </ul>
    </nav> -->
    <nav class="bg-white shadow">
      <div class="mx-auto px-2 sm:px-4 lg:px-6">
        <div class="flex h-16 justify-between">
          <div class="flex px-2 lg:px-0">
            <div class="mt-1 flex flex-shrink-0 items-center">
              <a href="/">
                <img
                  class="block h-8 w-auto lg:hidden"
                  src="/img/icon3.png"
                  alt="Ord.exchange"
                />
                <img
                  class="hidden h-8 w-auto lg:block"
                  src="/img/icon3.png"
                  alt="Ord.exchange"
                />
              </a>
            </div>
            <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
              <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
              <router-link to="/"
                class="inline-flex items-center hover:border-b-2 hover:border-gray-300 px-1 pt-1 text-sm font-medium text-gray-900"
                >Explore</router-link>

              <router-link to="/collections"
                class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >Collections
              </router-link>

              <router-link to="/about"
                class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >About</router-link>
              <!-- <a
                href="#"
                class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >List Your Collection</a
              > -->
            </div>
          </div>
          <div
            class="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end"
          >
            <div class="w-full max-w-lg lg:max-w-xs">
              <label for="search" class="sr-only">Search</label>
              <div class="relative">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <svg
                    class="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search inscriptions & collections"
                  type="search"
                  v-model="enteredSearch"
                  @keydown.enter="goToHome"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center lg:hidden">
            <!-- Mobile menu button -->
            <a href="https://discord.gg/Q4xFcS6hnQ" target="_blank">
              <button
                type="button"
                class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <i class="fa-brands fa-discord"></i>
              </button>
            </a>

            <a href="https://twitter.com/OrdExchange" target="_blank">
              <button
                type="button"
                class="ml-2 mr-2 flex text-gray-400 rounded-full bg-white text-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <i class="fa-brands fa-twitter"></i>
              </button>
            </a>

              </div>
          <div class="flex items-center lg:hidden">
            <!-- Mobile menu button -->
            <button
              @click="mobileMenu = !mobileMenu"
              type="button"
              class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <i class="fa fa-bars"></i>
            </button>
          </div>

          <div class="hidden lg:ml-4 lg:flex lg:items-center">
            <a href="https://discord.gg/Q4xFcS6hnQ" target="_blank">
              <button
                type="button"
                class="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <i class="fa-brands fa-discord"></i>
              </button>
            </a>

            <!-- Profile dropdown -->
            <div class="relative ml-4 flex-shrink-0">
              <div>
                <a href="https://twitter.com/OrdExchange" target="_blank">
                  <button
                    type="button"
                    class="flex text-gray-400 rounded-full bg-white text-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <i class="fa-brands fa-twitter"></i>
                  </button>
                </a>
              </div>

              <!--
                  Dropdown menu, show/hide based on menu state.
      
                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                -->
              <!-- Active: "bg-gray-100", Not Active: "" -->
              <!--
            <div
              class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
                >Your Profile</a
              >
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                >Settings</a
              >
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2"
                >Sign out</a
              >
            </div>
          -->
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div v-if="mobileMenu" class="hidde" id="mobile-menu">
        <div class="space-y-1 pt-2 pb-3">
          <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" -->
          <router-link
            to="/"
            class="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
            >Marketplace</router-link>

          <router-link
            to="/collections"
            class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
            >Collections</router-link>

          <router-link
            to="/about"
            class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
            >About</router-link>

        </div>
      </div>
    </nav>

    <router-view :btc-price="btcPrice" :entered-search="enteredSearch" @searchChanged="updateSearch"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    enteredSearch(value) {
      const query = { ...this.$route.query };
      if (value) {
        query.search = value;
      } else {
        delete query.search;
      }
      this.$router.replace({ query });
    },
  },
  components: {},
  data() {
    return {
      mobileMenu: false,
      btcPrice: null,
      enteredSearch: ""
      //this.$route?.query?.search ? this.$route.query.search : ""
    };
  },
  methods: {
    updateSearch: function(val) {
      this.enteredSearch = val;
    },
    getBTCPrice: function () {
      fetch('https://api.coinbase.com/v2/prices/BTC-USD/spot')
        .then(response => response.json())
        .then(data => {
          this.btcPrice = data.data.amount;
        })
        .catch(error => console.error(error));
    },
    goToHome() {
      if (this.enteredSearch.trim() !== '') {
        this.$router.push({ path: '/', query: this.$route.query })
      }
    }
  },
  mounted() {
    this.getBTCPrice();
    let search = new URLSearchParams(window.location.search).get('search');
    if(search) this.enteredSearch = search;
  }
};
</script>

<style>
.-mt-125 {
  margin-top: -0.15rem;
}

.router-link-active {
  border-bottom: 2px solid #5046e5;
}

/* a {
  color: #42b983;
  text-decoration: none;
} */
</style>
