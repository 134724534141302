<template>
  <div class="flex" style="height: calc(100vh - 113px);">

    <!-- BOTTOM BANNER -->
    <!-- <div class="z-10 pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
      <div class="bg-gradient-to-r from-purple-400 to-yellow-400 text-white pointer-events-auto flex items-center justify-between gap-x-6 py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4">
        <p class="text-sm leading-6">
          <a href="#"><strong class="font-semibold">
            Ord.Exchange</strong>
            is the first marketplace aggregator for Bitcoin ordinal inscriptions</a>
        </p>
        <button type="button" class="-m-1.5 flex-none p-1.5">
          <span class="sr-only">Dismiss</span>
          <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
          </svg>
        </button>
      </div>
    </div> -->

    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div v-if="showMobileFilters" class="relative z-40" role="dialog" aria-modal="true">
      <!--
  Off-canvas menu backdrop, show/hide based on off-canvas menu state.

  Entering: "transition-opacity ease-linear duration-300"
    From: "opacity-0"
    To: "opacity-100"
  Leaving: "transition-opacity ease-linear duration-300"
    From: "opacity-100"
    To: "opacity-0"
-->
      <div class="fixed inset-0 bg-gray-600 bg-opacity-75"></div>

      <div class="fixed inset-0 z-40 flex">
        <!--
    Off-canvas menu, show/hide based on off-canvas menu state.

    Entering: "transition ease-in-out duration-300 transform"
      From: "-translate-x-full"
      To: "translate-x-0"
    Leaving: "transition ease-in-out duration-300 transform"
      From: "translate-x-0"
      To: "-translate-x-full"
  -->
        <div class="relative flex w-full max-w-xs flex-1 flex-col bg-white">
          <!--
      Close button, show/hide based on off-canvas menu state.

      Entering: "ease-in-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in-out duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
          <div class="absolute top-0 right-0 -mr-12 pt-2">
            <button
              @click="showMobileFilters=false"
              type="button"
              class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <svg
                class="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <!-- MOBILE FILTERS -->
          <div class="h-0 flex-1 overflow-y-auto pt-5 pb-4 px-5">
            <h1 class="text-2xl font-semibol text-gray-900 mb-4">
              Filter
            </h1>
            <div class="border-gray-300 rounded-xl">
              <div
                class="font-semibold uppercase text-sm text-gray-400 mb-2"
              >
                Inscription # Range
              </div>
              <div class="flex">
                <input
                  type="text"
                  class="w-1/2 mr-2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Min"
                  v-model="number_min"
                  @change="fetchEverything()"
                />
                <input
                  type="text"
                  class="w-1/2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Max"
                  v-model="number_max"
                  @change="fetchEverything()"
                />
              </div>
              <div class="mt-2 mr-2 text-xs">
                Or try:
                <span class="text-indigo-500 font-medium">
                  <a @click="number_max = 100000; fetchEverything();" class="cursor-pointer">&lt;100k</a>,
                  <a @click="number_max = 10000; fetchEverything();" class="cursor-pointer">&lt;10k</a>,
                  <a @click="number_max = 1000; fetchEverything();" class="cursor-pointer">&lt;1k</a>
                  </span>
              </div>
            </div>

            <br />

            <div class="border-gray-300 rounded-xl">
              <div
                class="font-semibold uppercase text-sm text-gray-400 mb-2"
              >
                Price Range (BTC)
              </div>
              <div class="flex">
                <input
                  type="text"
                  class="w-1/2 mr-2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Min"
                  v-model="price_min"
                  @input="fetchEverything()"
                />
                <input
                  type="text"
                  class="w-1/2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Max"
                  v-model="price_max"
                  @input="fetchEverything()"
                />
              </div>
            </div>

            <br />

            <div class="font-semibold uppercase text-sm text-gray-400 mb-2">
              Collection
            </div>
            <select
              v-model="selectedCollection"
              @change="fetchEverything()"
              id="location"
              name="location"
              class="block w-full rounded-md border-0 py-3.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option :value="null">Any</option>
              <option
                v-for="collection in collections"
                :key="collection.slug"
                :value="collection"
              >
                {{ collection.name ?
                collection.name :
                collection.slug.charAt(0).toUpperCase() + collection.slug.slice(1) }} <span v-if="collection.count">({{ collection.count}})</span>
              </option>
            </select>

            <br />

            <div class="font-semibold uppercase text-sm text-gray-400 mb-2">
              Marketplaces
            </div>
            <div class="flex flex-col space-y-2">
              <label
                v-for="marketplace in marketplaces" :key="marketplace.slug"
                class="flex items-center"
              >
                <input
                  type="checkbox"
                  class="form-checkbox text-blue-500"
                  v-model="selectedMarketplaces"
                  :value="marketplace.slug"
                  @change="fetchEverything()"
                />
                <span class="ml-3 text-gray-600">
                  <img
                    :src="'/img/' + marketplace.slug + '.svg'"
                    alt="icon1"
                    class="float-left w-6 h-6 mr-1.5"
                  />
                  {{ marketplace.name }}
                </span>
              </label>
            </div>

            <div v-if="!hasNoFilters" class="text-indigo-600 text-center mt-6">
              <a @click="clearFilters()" class="cursor-pointer"><i class="fa-solid fa-xmark mr-1"></i> Clear Filters</a>
            </div>
          </div>
          <!-- END MOBILE FILTERS -->

        </div>

        <div class="w-14 flex-shrink-0"></div>
      </div>
    </div>

    <!-- LEFT SIDEBAR -->
    <div
      style="height: calc(100vh - 65px);"
      :class="hideLeftSidebar ? 'hidden float-left lg:inset-y-0 lg:flex lg:w-14 lg:flex-col' : 'hidden float-left lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex h-full min-h-0 flex-1 flex-col border-r border-t border-gray-200 bg-white pl-2"
      >
        <div v-if="hideLeftSidebar">
          <button
            @click="hideLeftSidebar = false"
            class="mt-5 text-sm inline-flex items-center justify-center h-10 w-10 text-gray-400 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
          >
            <i class="fa-solid fa-sliders"></i>
          </button>
        </div>
        <div v-else class="flex flex-1 flex-col overflow-y-auto pt-0 pb-4">
          <div class="pt-6 flex-1 bg-white px-2 sm:px-4 lg:px-4">
            <h1 class="text-2xl font-semibol text-gray-900 mb-4">
              Filter
              <button
                @click="hideLeftSidebar = true"
                class="float-right -mt-1 ml-2 text-sm inline-flex items-center justify-center h-10 w-10 text-gray-400 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
              >
                <i class="fa-solid fa-arrow-left"></i>
              </button>
            </h1>

            <!-- <label
              class="mb-4 relative inline-flex items-center cursor-pointer"
            >
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                checked
                disabled
              />
              <div
                class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-indigo-300 dark:peer-focus:ring-indigo-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-indigo-600"
              ></div>
              <span
                class="font-semibold uppercase text-sm text-gray-400 ml-3"
                >Buy Now</span
              >
            </label>

            <br /><br />-->

            <div class="border-gray-300 rounded-xl">
              <div
                class="font-semibold uppercase text-sm text-gray-400 mb-2"
              >
                Inscription # Range
              </div>
              <div class="flex">
                <input
                  type="text"
                  class="w-1/2 mr-2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Min"
                  v-model="number_min"
                  @change="fetchEverything()"
                />
                <input
                  type="text"
                  class="w-1/2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Max"
                  v-model="number_max"
                  @change="fetchEverything()"
                />
              </div>
              <div class="mt-2 mr-2 text-xs">
                Or try:
                <span class="text-indigo-500 font-medium">
                  <a @click="number_max = 100000; fetchEverything();" class="cursor-pointer">&lt;100k</a>,
                  <a @click="number_max = 10000; fetchEverything();" class="cursor-pointer">&lt;10k</a>,
                  <a @click="number_max = 1000; fetchEverything();" class="cursor-pointer">&lt;1k</a>
                  </span>
              </div>
              <!-- <button
                class="w-full border-2 border-gray-200 hover:border-gray-500 rounded-xl text-sm p-3 mt-2"
              >
                Apply
              </button> -->
            </div>

            <br />

            <div class="border-gray-300 rounded-xl">
              <div
                class="font-semibold uppercase text-sm text-gray-400 mb-2"
              >
                Price Range (BTC)
              </div>
              <div class="flex">
                <input
                  type="text"
                  class="w-1/2 mr-2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Min"
                  v-model="price_min"
                  @input="fetchEverything()"
                />
                <input
                  type="text"
                  class="w-1/2 border-2 border-gray-200 rounded-xl p-3"
                  placeholder="Max"
                  v-model="price_max"
                  @input="fetchEverything()"
                />
              </div>
              <!-- <button
                class="w-full border-2 border-gray-200 hover:border-gray-500 rounded-xl text-sm p-3 mt-2"
              >
                Apply
              </button> -->
            </div>

            <br />

            <div class="font-semibold uppercase text-sm text-gray-400 mb-2">
              Collection
            </div>
            <select
              v-model="selectedCollection"
              @change="fetchEverything()"
              id="location"
              name="location"
              class="block w-full rounded-md border-0 py-3.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option :value="null">Any</option>
              <option
                v-for="collection in collections"
                :key="collection.slug"
                :value="collection"
              >
                {{ collection.name ?
                collection.name :
                collection.slug.charAt(0).toUpperCase() + collection.slug.slice(1) }} <span v-if="collection.count">({{ collection.count}})</span>
              </option>
            </select>

            <br />

            <div class="font-semibold uppercase text-sm text-gray-400 mb-2">
              Marketplaces
            </div>
            <div class="flex flex-col space-y-2">
              <label
                v-for="marketplace in marketplaces" :key="marketplace.slug"
                class="flex items-center"
              >
                <input
                  type="checkbox"
                  class="form-checkbox text-blue-500"
                  v-model="selectedMarketplaces"
                  :value="marketplace.slug"
                  @change="fetchEverything()"
                />
                <span class="ml-3 text-gray-600">
                  <img
                    :src="'/img/' + marketplace.slug + '.svg'"
                    alt="icon1"
                    class="float-left w-6 h-6 mr-1.5"
                  />
                  {{ marketplace.name }}
                </span>
              </label>
            </div>

            <div v-if="!hasNoFilters" class="text-indigo-600 text-center mt-6">
              <a @click="clearFilters()" class="cursor-pointer"><i class="fa-solid fa-xmark mr-1"></i> Clear Filters</a>
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-shrink-0 border-t border-gray-200 p-4">
        <a href="#" class="group block w-full flex-shrink-0">
          <div class="flex items-center">
            <div>
              <img
                class="inline-block h-9 w-9 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div>
            <div class="ml-3">
              <p
                class="text-sm font-medium text-gray-700 group-hover:text-gray-900"
              >
                Tom Cook
              </p>
              <p
                class="text-xs font-medium text-gray-500 group-hover:text-gray-700"
              >
                View profile
              </p>
            </div>
          </div>
        </a>
      </div> -->
      </div>
    </div>

    <!-- MAIN -->
    <div class="flex flex-1 flex-col">
      <!-- <div
        class="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden"
      >
        <button
          type="button"
          class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span class="sr-only">Open sidebar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div> -->
      <main class="flex-1 h-full">

        <!-- TOP BANNER -->
        <!-- <div class="bg-gradient-to-r from-blue-400 to-purple-500 text-white relative isolate flex items-center gap-x-6 overflow-hidden py-2.5 px-6 sm:px-3.5 sm:before:flex-1">
          <p class="text-sm leading-6">
            <a href="#"><strong class="font-semibold">
              Ord.Exchange</strong>
              lets you browse all Bitcoin ordinal marketplaces at once!</a>
          </p>
          <div class="flex flex-1 justify-end">
            <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
              <span class="sr-only">Dismiss</span>
              <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div> -->

        <div class="py-5 md:py-6 h-full">
          <div
            class="pb-3 border-b border-gray-200 sticky mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            <div class="mb-2">
              <h1 class="text-2xl font-medium text-gray-900 float-left">
                {{ collectionTitle ? collectionTitle : 'Inscriptions' }}
              </h1>
              <button
                @click="fetchEverything()"
                class="-mt-1 ml-2 text-sm inline-flex items-center justify-center h-10 w-10 text-gray-400 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
              >
                <i :class="loading ? 'fa fa-light fa-sync-alt animate-spin' : 'fa fa-light fa-sync-alt'"></i>
              </button>

              <!-- Mobile filters button -->
              <button
                @click="showMobileFilters = true"
                class="flex lg:hidden -mt-1 ml-2 text-sm inline-flex items-center justify-center h-10 w-10 text-gray-400 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
              >
                <i class="fa fa-light fa-sliders"></i>
              </button>

              <div class="mt-2 md:mt-0 md:float-right">
                <select
                  v-model="sortOrder"
                  @change="fetchEverything()"
                  id="location"
                  name="location"
                  class="mt-0 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="number_asc"
                    >Inscription #: Low to High</option
                  >
                  <option value="number_desc"
                    >Inscription #: High to Low</option
                  >
                  <option value="price_asc">Price: Low to High</option>
                  <option value="price_desc">Price: High to Low</option>
                </select>
              </div>
            </div>
            <div class="text-gray-500 text-sm">
              Floor price: {{ floorPrice() }}
              <img
                src="/img/btc2.png"
                class="h-4 inline-block -ml-1 -mt-125"
              />
              <span class="font-bold text-gray-300 mx-1">|</span> Total
              items: {{ Number(totalCount).toLocaleString() }}
            </div>
          </div>
          <div
            ref="content"
            class="h-full overflow-y-scroll mx-auto max-w-7xl px-4 sm:px-6 lg:pl-8 lg:pr-4"
          >
            <!-- Your content -->
            <div class="flex flex-wrap justify-left mt-4">
              <div
                v-for="(listing, index) in filteredListings"
                :key="listing.inscriptionId"
                class="w-1/2 md:w-1/3 lg:w-1/4 pr-4"
              >
                <div
                  class="flex flex-col items-center rounded-xl relative shadow-lg hover:shadow-2xl unselected transition mb-5 group hover-shadow"
                >
                  <a :href="marketplacesObj[listing.source].url + listing.inscriptionId" target="_blank" class="absolute top-3 left-3">
                    <img
                      :src="'/img/' + listing.source + '.svg'"
                      class="h-6 w-6 rounded-full object-cover"
                    />
                  </a>
                  <router-link :to="{ name: 'Details', params: { inscriptionObj: JSON.stringify(listing), inscriptionId: listing.inscriptionId } }">

                  <iframe
                    sandbox="allow-scripts"
                    scrolling="no"
                    style="pointer-events: none;"
                    loading="lazy"
                    class="h-48 object-cover w-full rounded-t-xl"
                    :src="'https://ordinals.com/preview/' + listing.inscriptionId"
                    v-if="showIframe[index] && showIframe[index] === 2"
                  ></iframe>
                  <img
                    v-if="!showIframe[index]"
                    :src="'https://static.unisat.io/inscription/content/' + listing.inscriptionId"
                    class="h-48 object-cover w-full rounded-t-xl"
                    @error="showIframe[index] = 2"
                  />

                  <div class="p-3 w-full">
                    <div class="mb-2 text-black">
                      Inscription #{{ listing.inscriptionNumber != Infinity ?
                      Number(listing.inscriptionNumber).toLocaleString() : '???' }}
                    </div>
                    <div class="block text-sm text-gray-500">
                      {{ Math.round(listing.price*1000000)/1000000 }}
                      <img
                        src="/img/btc2.png"
                        class="h-4 inline-block -ml-1 -mt-125"
                      />
                      <button
                        class="text-xs float-right border-gray-300 border px-1 rounded font-bold text-indigo-600 hover:bg-indigo-600 hover:text-white"
                      >
                        DETAILS ›
                      </button>
                    </div>
                  </div>
                </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

    <!-- RIGHT SIDEBAR -->
    <div
      style="height: calc(100vh - 65px);"
      :class="hideRightSidebar ? 'hidden flex float-right lg:inset-y-0 lg:flex lg:w-14 lg:flex-col' : 'hidden flex float-right lg:inset-y-0 lg:flex lg:w-80 lg:flex-col'"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex h-full min-h-0 flex-1 flex-col border-l border-t border-gray-200 bg-white px-0"
      >
        <div v-if="hideRightSidebar">
          <button
            @click="hideRightSidebar = false"
            class="mt-5 text-sm inline-flex items-center justify-center h-10 w-10 text-gray-400 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
          >
            <i class="fa-solid fa-chart-line"></i>
          </button>
        </div>
        <div v-else class="flex flex-1 flex-col overflow-y-auto pt-0 pb-4">
          <div class="mt-5 flex-1 space-y- bg-white px-0">

            <span v-show="histogram">
              <h1 class="text-2xl font-semibol text-gray-900 mb-4 px-4">
                Floor
                <button
                  @click="hideRightSidebar = true"
                  class="float-right -mt-1 ml-2 text-sm inline-flex items-center justify-center h-10 w-10 text-gray-400 border border-gray-300 rounded-full  focus:outline-none"
                >
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </h1>

              <canvas ref="chart" class="pb-2 mb-2 pl-2"></canvas>
              <div class="border-b w-full mb-4"> </div>
            </span>


            <h1 class="text-2xl font-semibol text-gray-900 mb-4 px-4">
              Activity
              <!-- <button
                @click="hideRightSidebar = true"
                class="float-right -mt-1 ml-2 text-sm inline-flex items-center justify-center h-10 w-10 text-gray-400 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button> -->
            </h1>

            <a v-for="sale in filteredActivity" :key="sale.inscriptionId" :href="marketplacesObj[sale.source].url + sale.inscriptionId" target="_blank" class="px-4 flex w-full">
            <div
              :class="activityStyle(sale.price)"
            >
            <!-- <router-link :to="{ name: 'Details', params: { inscriptionId: sale.inscription.id } }" class="flex w-full"> -->
              <div class="flex relative">
                <img
                  :src="'/img/' + sale.source + '.svg'"
                  class="h-5 w-5 -mr-3 -mt-1 relative rounded-full object-cover"
                />
                <iframe
                  sandbox="allow-scripts"
                  scrolling="no"
                  style="pointer-events: none;"
                  loading="lazy"
                  class="h-11 w-11 rounded-xl object-cover"
                  :src="'https://ordinals.com/preview/' + sale.inscriptionId"
                ></iframe>
              </div>
              <div class="flex-1 ml-4">
                <div v-if="sale.name" class="text-sm font-medium">
                  {{  sale.name }}
                </div>
                <div v-else class="text-sm font-medium">
                  Inscription #{{
                  Number(sale.inscriptionNumber).toLocaleString() }}
                </div>
                <div class="text-sm text-gray-500">
                  {{ getTimeAgo(sale.date) }}
                </div>
              </div>
              <div
                class="text-right items-right justify-right ml-4 text-sm"
              >
                <div>
                  {{ Math.round(sale.price / 100000000 * 1000000)/1000000 }}
                  <img
                    src="/img/btc2.png"
                    class="h-4 inline-block -ml-1 -mt-125"
                  />
                </div>
                <div
                  class="text-gray-600 inline-block text-xs border border-gray-300 font-bold px-1.5 rounded bg-gray-200"
                >
                  SOLD
                </div>
              </div>
            </div>
          </a>


          </div>
        </div>
        <!-- <div class="flex flex-shrink-0 border-t border-gray-200 p-4">
        <a href="#" class="group block w-full flex-shrink-0">
          <div class="flex items-center">
            <div>
              <img
                class="inline-block h-9 w-9 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                alt=""
              />
            </div>
            <div class="ml-3">
              <p
                class="text-sm font-medium text-gray-700 group-hover:text-gray-900"
              >
                Tom Cook
              </p>
              <p
                class="text-xs font-medium text-gray-500 group-hover:text-gray-700"
              >
                View profile
              </p>
            </div>
          </div>
        </a>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { createChart } from 'lightweight-charts';

export default {
  name: "HelloWorld",
  props: {
    enteredSearch: {
      type: String,
      default: ""
    }
  },
  watch: {
    sortOrder(value) {
      const query = { ...this.$route.query };
      if (value) {
        query.sort = value;
      } else {
        delete query.sort;
      }
      this.$router.replace({ query });
    },
    selectedCollection(value) {
      const query = { ...this.$route.query };
      if (value && !this.$route.params.collection) {
        query.collection = value.slug;
      } else {
        delete query.collection;
      }
      this.$router.replace({ query });
    },
    price_min(value) {
      const query = { ...this.$route.query };
      if (value) {
        query.price_min = value;
      } else {
        delete query.price_min;
      }
      this.$router.replace({ query });
    },
    price_max(value) {
      const query = { ...this.$route.query };
      if (value) {
        query.price_max = value;
      } else {
        delete query.price_max;
      }
      this.$router.replace({ query });
    },
    number_min(value) {
      const query = { ...this.$route.query };
      if (value) {
        query.min = value;
      } else {
        delete query.min;
      }
      this.$router.replace({ query });
    },
    number_max(value) {
      const query = { ...this.$route.query };
      if (value) {
        query.max = value;
      } else {
        delete query.max;
      }
      this.$router.replace({ query });
    },
    selectedMarketplaces(value) {
      const query = { ...this.$route.query };
      const areEqual = this.selectedMarketsAreEqual(value);
      if(areEqual) {
        delete query.markets;
      } else {
        query.markets = value;
      }
      this.$router.replace({ query });
    },
    enteredSearch() {
      this.fetchEverything();
    }
  },
  data() {
    return {
      histogram: null,
      showIframe: [],
      lowestPrice: null,
      showMobileFilters: false,
      loading: false,
      totalCount: 0,
      hideLeftSidebar: false,
      hideRightSidebar: false,
      listings: [],
      selectedCollection: null,
      selectedMarketplaces: this.$route && this.$route.query && this.$route.query.markets ? (Array.isArray(this.$route.query.markets) ? this.$route.query.markets : [this.$route.query.markets]) : [
        "ordinalswallet",
        "ordswap",
        "scarcecity",
        "openordex"
      ],
      sortOrder: this.$route && this.$route.query && this.$route.query.sort ? this.$route.query.sort : "number_asc",
      number_min: this.$route && this.$route.query && this.$route.query.min ? Number(this.$route.query.min) : null,
      number_max: this.$route && this.$route.query && this.$route.query.max ? Number(this.$route.query.max) : null,
      price_min: this.$route && this.$route.query && this.$route.query.price_min ? Number(this.$route.query.price_min) : null,
      price_max: this.$route && this.$route.query && this.$route.query.price_max ? Number(this.$route.query.price_max) : null,
      collections: [],
      sales: [],
      lastShownIndex: 16,
      salesFetchInterval: null,
      marketplaces: [
        {
          slug: "ordinalswallet",
          name: "Ordinals Wallet"
        },
        {
          slug: "ordswap",
          name: "Ordswap"
        },
        {
          slug: "scarcecity",
          name: "Scarce City"
        },
        {
          slug: "openordex",
          name: "OpenOrdex"
        }
      ],
      marketplacesObj: {
        'ordinalswallet': {
          slug: "ordinalswallet",
          name: "Ordinals Wallet",
          url: "https://ordinalswallet.com/inscription/"
        },
        'ordswap': {
          slug: "ordswap",
          name: "Ordswap",
          url: "https://ordswap.io/inscription/"
        },
        'scarcecity': {
          slug: "scarcecity",
          name: "Scarce City",
          url: "https://scarce.city/marketplace/digital/"
        },
        'openordex': {
          slug: "openordex",
          name: "OpenOrdex",
          url: "https://openordex.org/inscription?number="
        }
      }
    };
  },
  computed: {
    collectionTitle() {
      if(this.selectedCollection && this.selectedCollection.name) {
        return this.selectedCollection.name;
      }
      else if(this.selectedCollection && this.selectedCollection.slug) {
        return this.selectedCollection.slug.replace(/-/g, " ").split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
      } else {
        return null;
      }
    },
    hasNoFilters() {
      return (!this.enteredSearch || this.enteredSearch.trim() == '')
        && !this.number_min && !this.number_max
        && !this.price_min && !this.price_max
        && !this.selectedCollection
        && this.selectedMarketsAreEqual(this.selectedMarketplaces)
    },
    filteredActivity() {
      return this.sales.slice(0, 100);
    },
    uniqueCollections() {
      const collections = new Set();
      for (const item of this.listings) {
        if (item.collectionSlug && item.collectionSlug !== "")
          collections.add(item.collectionSlug);
      }
      return Array.from(collections).sort();
    },
    allFetchedCollections() {
      const obj = {};
      for (let i = 0; i < this.collections.length; i++) {
        obj[this.collections[i].slug] = this.collections[i];
      }
      console.log("allFetchedCollections = ", obj);
      return obj;
    },
    filteredListings() {
      let listings = this.listings;

      // Uniquify listings to lowest price
      const groups = listings.reduce((accumulator, currentItem) => {
        if (!accumulator[currentItem.inscriptionNumber]) {
          accumulator[currentItem.inscriptionNumber] = [currentItem];
        } else {
          accumulator[currentItem.inscriptionNumber].push(currentItem);
        }
        return accumulator;
      }, {});
      listings = Object.values(groups).map((group) => {
        return group.reduce((accumulator, currentItem) => {
          if (!accumulator || currentItem.price < accumulator.price) {
            accumulator = currentItem;
          }
          return accumulator;
        }, null);
      });

      // FILTERS
      if (this.price_min) {
        listings = listings.filter((item) => {
          return item.price >= Number(this.price_min);
        });
      }
      if (this.price_max) {
        listings = listings.filter((item) => {
          return item.price <= Number(this.price_max);
        });
      }
      if (this.number_min) {
        listings = listings.filter((item) => {
          return item.inscriptionNumber >= Number(this.number_min);
        });
      }
      if (this.number_max) {
        listings = listings.filter((item) => {
          return item.inscriptionNumber <= Number(this.number_max);
        });
      }
      // Filter: Collection
      if (this.selectedCollection) {
        listings = listings.filter((item) => {
          return item.collectionSlug == this.selectedCollection.slug;
        });
      }
      // Filter: Marketplaces
      if (this.selectedMarketplaces.length > 0) {
        listings = listings.filter((item) => {
          return this.selectedMarketplaces.indexOf(item.source) !== -1;
        });
      }

      // SEARCH
      if (this.enteredSearch) {
        listings = listings.filter((item) => {
          return (
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(this.enteredSearch.toLowerCase()) !== -1
          );
        });
      }

      // SORT
      switch (this.sortOrder) {
        case "number_asc":
          listings.sort((a, b) => a.inscriptionNumber - b.inscriptionNumber);
          break;
        case "number_desc":
          listings.sort((a, b) => b.inscriptionNumber - a.inscriptionNumber);
          break;
        case "price_asc":
          listings.sort((a, b) => a.price - b.price);
          break;
        case "price_desc":
          listings.sort((a, b) => b.price - a.price);
          break;
        default:
          break;
      }
      return listings;
    },
    shownFilteredListings() {
      return this.filteredListings.slice(0, this.lastShownIndex);
    }
  },
  methods: {
    generateHistogram() {
      let data = this.histogram;
      //fetch('https://ordexapi.xyz/histogram').then(response => response.json()).then((data) => {
        /*data.shift();
        data = data.slice(0,92);
        console.log(data);
        this.histogram = data;
        // Create the chart
        const chart = createChart(this.$refs.chart, {
          width: 300,
          height: 300
        });
        const series = chart.addHistogramSeries();
        series.setData(this.histogram);
        chart.timeScale().fitContent();*/

        data = data.filter(item => Number(item.x) < 10 );

        var canvas = this.$refs.chart;
        var ctx = canvas.getContext('2d');
        var maxValue = -Infinity;
        var minX = Infinity;
        var maxX = -Infinity;
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          if (item.value > maxValue) {
            maxValue = item.value;
          }
          if (item.x < minX) {
            minX = item.x;
          }
          if (item.x > maxX) {
            maxX = item.x;
          }
        }
        var barWidth = 40;

        let height = 135;
        canvas.width = 300;
        canvas.height = height;

        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          var barHeight = item.value / maxValue * (height);
          var x = item.x * 50 + 10;
          var y = height - barHeight;
          ctx.fillStyle = '#e1e5eb';
          ctx.fillRect(x, y-20, barWidth, barHeight);
          // Add mousemove event listener to canvas
          // canvas.addEventListener('mousemove', function(event) {
          //   var rect = canvas.getBoundingClientRect();
          //   var x = event.clientX - rect.left;
          //   var y = event.clientY - rect.top;
          //   for (var i = 0; i < data.length; i++) {
          //     var item = data[i];
          //     var barHeight = item.value / maxValue * (height);
          //     var barWidth = 40;
          //     var xPosition = item.x * 50 + 10;
          //     var yPosition = height - barHeight;
          //     if (x >= xPosition && x <= xPosition + barWidth && y >= yPosition - 20 && y <= height) {
          //       // Draw tooltip
          //       var tooltipWidth = 50;
          //       var tooltipHeight = 20;
          //       var tooltipX = xPosition + barWidth / 2 - tooltipWidth / 2;
          //       var tooltipY = yPosition - tooltipHeight;
          //       ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
          //       ctx.fillRect(tooltipX, tooltipY, tooltipWidth, tooltipHeight);
          //       // Draw tooltip text
          //       ctx.fillStyle = 'white';
          //       ctx.font = '14px Arial';
          //       ctx.fillText(item.x.toFixed(2), tooltipX + tooltipWidth / 2, tooltipY + tooltipHeight / 2);
          //     }
          //   }
          // });
        }

        // Add x-axis labels
        var xRange = maxX - minX;
        var xStep = Math.ceil(xRange / 4);
       //console.log(maxX, minX, xRange, xStep);
        ctx.fillStyle = '#888';
        ctx.font = '12px Arial';
        for (var x = minX; x <= maxX; x += xStep) {
          var xPosition = (x - minX) / xRange * 300 + 10;
          ctx.fillText(x, xPosition, height - 0);
        }
      // // Count the frequency of each number
      // let counts = {};
      // for (let i = 0; i < this.numbers.length; i++) {
      //   let num = this.numbers[i];
      //   counts[num] = counts[num] ? counts[num] + 1 : 1;
      // }

      // // Convert the counts object into an array of data points
      // let data = [];
      // for (let num in counts) {
      //   let count = counts[num];
      //   data.push({ time: num, value: count });
      // }
      //});
    },
    floorPrice() {
      if (this.lowestPrice)
        return this.lowestPrice;
      if (!this.filteredListings || this.filteredListings.length === 0)
        return 0;
      const prices = this.filteredListings.map((item) => item.price);
      const lowestPrice = Math.min.apply(Math, prices);
      return Math.round(lowestPrice * 10000000) / 10000000;
    },
    clearFilters: function () {
      this.price_min = null;
      this.price_max = null;
      this.number_max = null;
      this.number_min = null;
      this.$emit('searchChanged', '');
      this.selectedCollection = null;
      this.selectedMarketplaces = [
        "ordinalswallet",
        "ordswap",
        "scarcecity",
        "openordex"
      ];
      this.fetchEverything();
    },
    selectedMarketsAreEqual: function(value) {
      let og = new Set(this.marketplaces.map(item => { return item.slug }));
      let news = new Set(value);
      return og.size === news.size && [...og].every(value => news.has(value));
    },
    getTimeAgo: function (timestamp) {
      const msPerMinute = 60 * 1000;
      const msPerHour = msPerMinute * 60;
      const msPerDay = msPerHour * 24;
      const msPerMonth = msPerDay * 30;
      const msPerYear = msPerDay * 365;

      const currentTimestamp = new Date().getTime();
      const timeAgoInMs = currentTimestamp - timestamp;

      if (timeAgoInMs < msPerMinute) {
        const secondsAgo = Math.floor(timeAgoInMs / 1000);
        return secondsAgo + " seconds ago";
      } else if (timeAgoInMs < msPerHour) {
        const minutesAgo = Math.floor(timeAgoInMs / msPerMinute);
        return minutesAgo + " minutes ago";
      } else if (timeAgoInMs < msPerDay) {
        const hoursAgo = Math.floor(timeAgoInMs / msPerHour);
        return hoursAgo + " hours ago";
      } else if (timeAgoInMs < msPerMonth) {
        const daysAgo = Math.floor(timeAgoInMs / msPerDay);
        return daysAgo + " days ago";
      } else if (timeAgoInMs < msPerYear) {
        const monthsAgo = Math.floor(timeAgoInMs / msPerMonth);
        return monthsAgo + " months ago";
      } else {
        const yearsAgo = Math.floor(timeAgoInMs / msPerYear);
        return yearsAgo + " years ago";
      }
    },
    fetchOrdex: function () {
      let url = "https://ordexapi.xyz/listings?page=" + this.currentPage + "&limit=100";

      let sortString = '';
      switch (this.sortOrder) {
        case "number_asc":
          sortString = 'inscriptionNumber';
          break;
        case "number_desc":
          sortString = '-inscriptionNumber';
          break;
        case "price_asc":
          sortString = 'price';
          break;
        case "price_desc":
          sortString = '-price';
          break;
        default:
          sortString = 'inscriptionNumber';
          break;
      }
      url += "&sort=" + sortString;

      if(!this.selectedMarketsAreEqual(this.selectedMarketplaces)) {
        url += '&markets=' + this.selectedMarketplaces.join(',');
      }

      if(this.enteredSearch) {
        url += '&search=' + this.enteredSearch;
      }

      if(this.selectedCollection) {
        url += '&collection=' + (this.selectedCollection.slug ? this.selectedCollection.slug : this.selectedCollection);
      }

      if(this.price_max) {
        url += "&price_max=" + this.price_max;
      }
      if(this.price_min) {
        url += "&price_min=" + this.price_min;
      }
      if(this.number_max) {
        url += "&max=" + this.number_max;
      }
      if(this.number_min) {
        url += "&min=" + this.number_min;
      }

      this.loading = true;
      fetch(url, {
        method: "GET",
        mode: "cors",
      })
        .then((d) => d.json())
        .then((results) => {
          this.loading = false;
          console.log("Ordex =", results.listings);
          this.listings = this.listings.concat(results.listings);
          this.totalCount = results.totalCount;
          this.lowestPrice = results.lowestPrice ? results.lowestPrice : null;
          this.histogram = results.histogram ? results.histogram : null;
          if(this.histogram) this.generateHistogram()
          console.log("Listings = ", this.listings);
        });
    },
    fetchOrdswapActivity: function () {
      fetch("https://graphql.ordswap.io/", {
        headers: {
          accept: "application/graphql+json, application/json",
          "accept-language": "en-US,en;q=0.9",
          "content-type": "application/json",
          "sec-ch-ua":
            '"Chromium";v="110", "Not A(Brand";v="24", "Google Chrome";v="110"',
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": '"macOS"',
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
          "sec-fetch-site": "same-site"
        },
        referrer: "https://ordswap.io/",
        referrerPolicy: "strict-origin-when-cross-origin",
        body:
          '{"query":"{\\n  activity {\\n    price\\n    filled\\n    inscription {\\n      id\\n      number\\n      ct\\n      inline\\n      collection {\\n        slug\\n        name\\n        __typename\\n      }\\n      __typename\\n    }\\n    __typename\\n  }\\n}","variables":{}}',
        method: "POST",
        mode: "cors",
        credentials: "omit"
      })
        .then((d) => d.json())
        .then((d) => {
          let results = d.data.activity;
          console.log("OrdSwap activity =", results);
          const newArray = results.map(obj => {
            const newObj = { ...obj };
            newObj.inscriptionId = obj.inscription.id;
            newObj.inscriptionNumber = obj.inscription.number;
            newObj.date = obj.filled;
            newObj.source = "ordswap";
            return newObj;
          });
          this.sales = this.sales.concat(newArray);
          this.sales.sort((a,b) => b.date - a.date );
        });
    },
    fetchOrdinalsWalletActivity: function () {
      fetch("https://turbo.ordinalswallet.com/inscriptions/activity?limit=50&offset=0&order=BoughtAtDesc", {
        "method": "GET",
        "mode": "cors",
        "credentials": "omit"
      })
        .then((d) => d.json())
        .then((d) => {
          console.log("OrdinalsWallet activity =", d);
          const newArray = d.map(obj => {
            const newObj = { ...obj };
            newObj.inscriptionId = obj.id;
            newObj.inscriptionNumber = obj.num;
            newObj.date = Math.floor(new Date(Date.parse(obj.escrow.bought_at)).getTime());
            newObj.price = obj.escrow.satoshi_price;
            newObj.name = obj.meta && obj.meta.name ? obj.meta.name : null;
            newObj.source = "ordinalswallet";
            return newObj;
          });
          this.sales = this.sales.concat(newArray);
          this.sales.sort((a,b) => b.date - a.date );
        });
    },
    handleScroll: function () {
      const content = this.$refs.content;
      const scrollPosition = content.scrollTop + content.offsetHeight;
      const bottomThreshold = 50; // adjust as needed
      if (scrollPosition > content.scrollHeight - bottomThreshold) {
        // const newIndex = this.lastShownIndex + 16;
        // if (newIndex < this.filteredListings.length) {
        //   this.lastShownIndex = newIndex;
        // }
        this.currentPage++;
        this.fetchOrdex();
      }
    },
    fetchCollections: function () {
      fetch("https://ordexapi.xyz/collections").then(response => response.json()).then((collectionInDb) => {
        fetch(
        "https://raw.githubusercontent.com/orenyomtov/openordex/main/static/collections.json"
      )
        .then((response) => response.json())
        .then((data) => {
          //const collectionSlugs = collectionInDb.map(item => item.collectionSlug);
          //console.log(collectionSlugs);
          this.collections = collectionInDb.map(cs => {
            const item = data.find(d => d.slug === cs.collectionSlug);
            return item ? {...item, count: cs.count} : { slug: cs.collectionSlug, name: cs.collectionSlug.replace(/-/g, " ").split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") };
          }).filter(item => item !== null);
          //console.log(this.$route);
          if(this.$route && this.$route.query && this.$route.query.collection) {
            let foundCollection = this.collections.find(d => d.slug === this.$route.query.collection);
            this.selectedCollection = foundCollection ? foundCollection : this.$route.query.collection;
          } else if(this.$route && this.$route.params && this.$route.params.collection) {
            let foundCollection = this.collections.find(d => d.slug === this.$route.params.collection);
            this.selectedCollection = foundCollection ? foundCollection : this.$route.params.collection;
          } else {
            this.selectedCollection = null;
          }

          this.fetchEverything();

          console.log("Collections = ", this.collections, this.selectedCollection);
        });
      })
    },
    activityStyle: function(price) {
      price = price / 100000000;
      if(price > 0.01) {
        return "w-full flex items-center py-2 hover:bg-gray-100 bg-green-100";
      } else {
        return "w-full flex items-center py-2 hover:bg-gray-100";
      }
    },
    fetchActivity: function () {
      this.sales = [];
      this.fetchOrdinalsWalletActivity();
      this.fetchOrdswapActivity();
    },
    fetchEverything: function () {
      this.totalCount = 0;
      this.currentPage = 0;
      this.listings = [];
      this.fetchOrdex();
    }
  },
  mounted() {
    this.fetchCollections();
    this.fetchActivity();
    this.salesFetchInterval = setInterval(() => {
      this.fetchActivity();
    }, 240000); // 4 minutes
    const content = this.$refs.content;
    content.addEventListener("scroll", this.handleScroll);
  }
};
</script>
