<template>
  <main class="flex-1 h-full">
        <div class="py-6 h-full">
          <div
            class="pb-3 border- border-gray-200 mx-auto px-4 sm:px-6 lg:px-8"
          >
            <div class="text-center mb-1 mt-6">
              <h1 class="text-3xl font-semibold text-gray-900">
                🔥 &nbsp;Trending Ordinals Collections
              </h1>
          </div>
        </div>
      </div>
      <div class="w-11/12 mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flow-root">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr class="text-gray-400">
                    <th scope="col" class="py-3.5 pl-3.5 pr-3 text-left text-sm sm:pl-4 font-normal">Collection</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal">Floor Price</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal">Listed</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal">Total Supply</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal">% Listed</th>
                  </tr>
                </thead>
                <tbody v-if="collections" class="divide-y divide-gray-200 bg-white">
                  <tr @click="$router.push({ path: '/', query: { collection: collection.slug } })" v-for="collection in collections" :key="collection.slug" class="font-medium hover:bg-gray-100 cursor-pointer">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                      <div class="pl-4 flex items-center">
                        <div class="h-10 w-10 flex-shrink-0">
                          <iframe
                            sandbox="allow-scripts"
                            scrolling="no"
                            style="pointer-events: none;"
                            loading="lazy"
                            class="h-10 w-10 object-cover rounded-lg"
                            :src="'https://ordinals.com/preview/' + collection.inscription_icon"
                          ></iframe>
                        </div>
                        <div class="ml-4">
                          <div class="font-medium text-gray-900">{{  collection.name }} <img src="/img/verified.svg" class="h-4 inline" /></div>
                        </div>
                      </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="text-gray-800 text-base font-semibold">{{  Math.round(collection.lowestPrice * 1000000)/1000000 }}  <img
                        src="/img/btc2.png"
                        class="h-4 inline-block -ml-1 -mt-125"
                      /></div>
                      <div v-if="btcPrice" class="text-gray-400 text-xs">${{ Number(Number(collection.lowestPrice * this.btcPrice).toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                      {{ collection.count }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800">{{ Number(collection.supply).toLocaleString() }}</td>
                    <td class="relative whitespace-nowrap py-4 pl-3 text-gray-800 pr-4 text-sm sm:pr-0">
                      {{ Number(Number(collection.count / Number(collection.supply)) * 100).toFixed(2) }}%
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
</template>

<script>
export default {
  name: "Collections",
  props: {
    btcPrice: Number
  },
  data() {
    return {
      collections: null
    }
  },
  methods: {
    fetchCollections: function () {
      fetch("https://ordexapi.xyz/collections").then(response => response.json()).then((collectionInDb) => {
        fetch("https://raw.githubusercontent.com/orenyomtov/openordex/main/static/collections.json").then((response) => response.json()).then((data) => {
          this.collections = collectionInDb.map(cs => {
            const item = data.find(d => d.slug === cs.collectionSlug);
            return item ? {...item, count: cs.count, lowestPrice: cs.lowestPrice} : null;
          }).filter(item => item !== null);
          console.log("Collections = ", this.collections);
        });
      })
    },
  },
  mounted() {
    this.fetchCollections();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>