<template>
  <main class="flex-1 h-full">
        <div class="py-6 h-full">
          <div
            class="pb-3 border-b border-gray-200 mx-auto px-4 sm:px-6 lg:px-8"
          >
            <div class="mb-1">
              <h1 class="text-2xl font-semibold text-gray-900">
                About
              </h1>
          </div>
        </div>
      </div>
      <div class="px-4 sm:px-6 lg:px-8">
        Ord.exchange is a Bitcoin Ordinals marketplace aggregator. Browse Ordinals for sale, across many different marketplaces!
      </div>
    </main>
</template>

<script>
export default {
  name: "goodbye",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>