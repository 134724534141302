import { createApp } from "vue";
import App from "./App.vue";
import HelloWorld from "./components/HelloWorld";
import About from "./components/about";
import Details from "./components/Details";
import Collections from "./components/Collections";
import { createRouter, createWebHistory } from "vue-router";

const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  // history: createWebHistory(),
  routes: [
    {
      path: "/inscription/:inscriptionId",
      component: Details,
      name: "Details",
      props: true
    },
    {
      path: "/collections",
      component: Collections
    },
    {
      path: "/about",
      component: About
    },
    {
      path: "/collection/:collection",
      name: "Collection",
      component: HelloWorld,
      props: true
    },
    {
      path: "/",
      name: "Home",
      component: HelloWorld
    }
  ]
});
const app = createApp(App);
app.use(router);

app.mount("#app");
// createApp(App).mount('#app')
