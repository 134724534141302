<template>
  <main class="flex-1 h-full">
    <div class="mt-4 flex flex-col md:flex-row">
  <div class="md:w-5/12 p-4">
    <div
                  class="flex flex-col items-center rounded-xl relative shadow-lg hover:shadow-2xl unselected transition mb-5 group p-4 border border-gray-50"
                >
                  <iframe
                    sandbox="allow-scripts"
                    scrolling="no"
                    style="pointer-events: none;"
                    loading="lazy"
                    class="iframe object-cover w-full rounded-xl"
                    :src="'https://ordinals.com/preview/' + inscriptionId"
                    v-if="showIframe === true"
                  ></iframe>
                  <img
                    v-if="!showIframe"
                    :src="'https://static.unisat.io/inscription/content/' + inscriptionId"
                    class="w-full object-cover w-full rounded-xl"
                    @error="showIframe = true"
                  />
                  <!-- <div class="p-3 w-full">
                    <div class="mb-2 text-black">
                      Inscription #{{ Number(inscriptionNumber).toLocaleString() }}
                    </div>
                    <div class="block text-sm text-gray-500">
                      {{ Math.round(price*1000000)/1000000 }}
                      <img
                        src="/img/btc2.png"
                        class="h-4 inline-block -ml-1 -mt-125"
                      />
                      <button
                        class="text-xs float-right border-gray-300 border px-1 rounded font-bold text-indigo-600 hover:bg-indigo-600 hover:text-white"
                      >
                        DETAILS ›
                      </button>
                    </div>
                  </div> -->
                </div>
  </div>
  <div class="md:w-7/12 p-4">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          <!-- <a @click="$router.go(-1)" class="cursor-pointer"><i class="fa-solid fa-chevron-left"></i></a> -->
           {{ inscriptionName }}
        </h2>
        <div v-if="inscriptionOwner" class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-2 flex items-center text-base text-gray-500">
            Owned by&nbsp; <a :href="'https://mempool.space/address/' + inscriptionOwnerRaw" target="_blank">{{ inscriptionOwner }}</a>
          </div>
        </div>
      </div>
      <!-- <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span class="hidden sm:block">
          <button type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <svg class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
            </svg>
            Edit
          </button>
        </span>

        <span class="ml-3 hidden sm:block">
          <button type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <svg class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
              <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
            </svg>
            View
          </button>
        </span>

        <span class="sm:ml-3">
          <button type="button" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Publish
          </button>
        </span>
      </div> -->
    </div>
    <div class="mt-6 items-left text-sm text-gray-800">
      <div class="mr-2 inline-block font-bold text-xs rounded-lg p-2 py-1 border border-gray-300">
        #{{  inscriptionNumber }}
      </div>
      <div class="mr-2 inline-block font-bold text-xs rounded-lg p-2 py-1 border border-gray-300">
        Ordinal
      </div>

      <router-link v-if="inscription && inscription.collectionSlug" :to="'/?collection=' + inscription.collectionSlug">
      <div class="bg-gradient-to-r from-purple-400 to-yellow-400 inline-block font-bold text-xs rounded-lg p-2 py-1 border border-gray-100 text-white">
        {{ inscription.collectionSlug }}
      </div>
    </router-link>
    </div>

    <div v-if="inscriptionPrice" class="mt-6 rounded-lg border border-gray-300 p-4">
      <p class="mb-2 text-gray-400">
        Lowest Price
        <img :src="'/img/' + marketplaces[inscription.source].slug + '.svg'" alt="icon1" class="inline-block w-6 h-6 -mt-1 ml-1.5" />
      </p>
      <p class="text-3xl font-bold text-gray-900 mb-4">{{  inscriptionPrice }} BTC&nbsp; <span v-if="btcPrice" class="text-sm text-gray-400 font-normal">${{ usdTotal }}</span></p>
      <div class="flex">
        <a :href="marketplaces[inscription.source].url + (inscription.source != 'scarcecity' ? inscription.inscriptionId : inscription.inscriptionNumber)" target="_blank" class="w-1/2 mr-4">
        <button class="w-full bg-blue-500 hover:bg-blue-400 text-white font-bold py-4 px-4 rounded-lg">
          <i class="fa fa-shopping-cart mr-2 "/> Buy on {{ marketplaces[inscription.source].name }}
        </button>
        </a>
        <a :href="'https://ordinals.com/inscription/' + inscription.inscriptionId" target="_blank" class="w-1/2">
          <button class="w-full bg-white border-gray-300 border hover:shadow hover:bg-gray-100 text-blue-500 font-bold py-4 px-4 rounded-lg">
            <i class="fa fa-eye mr-2 "/>  View on Ordinals.com
          </button>
        </a>
      </div>
    </div>

    <div v-if="inscriptionDetails" class="mt-6 flex flex-wrap w-full">
      <div v-for="(value, key) in inscriptionDetails" :key="key" class="flex w-1/2 md:w-4/12 flex-col p-1.5">
        <div class=" rounded-xl border px-4 py-4"><div class="flex text-sm text-text-subdued">{{ formatTagName(key) }}</div><div class="flex max-w-full "><div class="mt-0 max-w-full truncate text-sm text-gray-400">{{ value }}</div></div></div>
      </div>
    </div>
  </div>
</div>
    </main>
</template>

<script>
export default {
  name: "Details",
  props: {
    inscriptionObj: { type: Object },
    inscriptionId: { type: String },
    btcPrice: Number,
    //source: String
  },
  data() {
    return {
      showIframe: false
    }
  }, 
  computed: {
    usdTotal() {
      return Number(Number(this.inscription.price * this.btcPrice).toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    inscriptionPrice() {
      return this.inscription && this.inscription.price ? Math.round(this.inscription.price * 1000000)/1000000 : false;
    },
    inscriptionOwnerRaw() {
      if(this.inscriptionDetails && this.inscriptionDetails.address)
        return this.inscriptionDetails.address;
      else if(this.inscription && this.inscription.owner)
        return this.inscription.owner;
      else return false;
    },
    inscriptionOwner() {
      if(this.inscriptionDetails && this.inscriptionDetails.address)
        return this.inscriptionDetails.address.slice(0,6) + '...' + this.inscriptionDetails.address.slice(-6);
      else if(this.inscription && this.inscription.owner)
        return this.inscription.owner.slice(0,6) + '...' + this.inscription.owner.slice(-6);
      else return false;
    },
    inscriptionName() {
      return this.inscription && this.inscription.name ? this.inscription.name : 'Inscription #' + this.inscriptionNumber;
    },
    inscriptionNumber() {
      return this.inscription && this.inscription.inscriptionNumber ? Number(this.inscription.inscriptionNumber).toLocaleString() : '???'
    }
  },
  methods: {
    fetchInscriptionDetails: function (id) {
      fetch("https://ordapi.xyz/inscription/" + id, {
        method: "GET",
        mode: "cors",
      })
      .then((d) => d.json())
      .then((results) => {
        console.log("Details = ", results);
        if(results['content_length']) delete results['content_length'];
        if(results['content_type']) delete results['content_type'];
        if(results['genesis_fee']) delete results['genesis_fee'];
        if(results['genesis_transaction']) delete results['genesis_transaction'];
        if(results['output_value']) delete results['output_value'];
        this.inscriptionDetails = results;
      });
    },
    formatTagName: function (input) {
      return input.replace(/_/g, ' ').replace(/\b\w/g, function(match) {
        return match.toUpperCase();
      });
    }
  },
  data() {
    return {
      inscriptionDetails: null,
      inscription: null,
      marketplaces: {
        'ordinalswallet': {
          slug: "ordinalswallet",
          name: "Ordinals Wallet",
          url: "https://ordinalswallet.com/inscription/"
        },
        'ordswap': {
          slug: "ordswap",
          name: "Ordswap",
          url: "https://ordswap.io/inscription/"
        },
        'scarcecity': {
          slug: "scarcecity",
          name: "Scarce City",
          url: "https://scarce.city/marketplace/digital/"
        },
        'openordex': {
          slug: "openordex",
          name: "OpenOrdex",
          url: "https://openordex.org/inscription?number="
        }
      }
    }
  },
  mounted() {
    if(this.inscriptionObj) {
      this.inscription = JSON.parse(this.inscriptionObj);
      this.fetchInscriptionDetails(this.inscription.inscriptionId);
    } 
    else if(this.inscriptionId) {
      fetch("https://ordexapi.xyz/inscription?id=" + this.inscriptionId, {
        method: "GET",
        mode: "cors",
      })
      .then((d) => d.json())
      .then((results) => {
        console.log(results);
        // if((!results || results.length === 0) && this.source) {
        //   window.location.href = this.marketplaces[this.source].url + this.inscriptionId;
        // } else {
          this.inscription = results[0];
          this.fetchInscriptionDetails(this.inscription.inscriptionId);
        //}
      });
    } else {
      // redirect home
    }


    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
iframe {
  height: 36rem;
}
a {
  color: #5046e6;
}
</style>